import { FileResult } from "@/components/upload-file/FileResult";
import axios from "axios";

export class FileService {
    url = `${process.env.VUE_APP_GATEWAY_URL}file/api/v1/files`

    async download(file: FileResult) {
        const result: any = (await axios.get(`${this.url}/${file.id}`, {responseType: 'blob'}));
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.name}${file.extension}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        return true;
    }
}
