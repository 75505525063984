











































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import UploadFileButton from "@/components/upload-file/UploadFileButton.vue";
import { FileResult } from "@/components/upload-file/FileResult";
import { FileService } from "@/views/upload-file/FileService";
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {
        UploadFileButton
    }
})
export default class UploadList extends Vue {
    @Prop({default: () => []}) files: FileResult[];
    @Prop({default: false}) isShowUploadButton: boolean;
    @Prop({default: false}) isShowActionButton: boolean;
    selected = [];

    @Emit('uploaded')
    onFileUploaded(files: FileResult[]) {
        return files;
    }

    @Emit('deleted')
    onFilesDeleted(fileIds: string[]) {
        return fileIds;
    }

    fileUploaded(files: FileResult[]) {
        this.onFileUploaded(files);
    }

    download() {
        console.log(this.selected);
    }

    deleteAll() {
        console.log(this.selected);
        if (this.selected.length === 0) {
            return;
        }
        this.onFilesDeleted(this.selected.map((file: FileResult) => {
            return file.id;
        }));
    }

    deleteFile(file: FileResult) {
        this.onFilesDeleted([file.id]);
    }

    async downloadFile(file: FileResult) {
        await new FileService().download(file);
    }
}
