

























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { ProductOrderSearchResult } from "@/views/guarantee/models/ProductOrderSearchResult";
import { GuaranteeService } from "@/views/guarantee/GuaranteeService";
import { SearchResult } from "@/models/SearchResult";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { Money } from "v-money";
import cloneDeep from 'lodash/cloneDeep'

@Component({
    components: {
        flatPickr,
        VuePerfectScrollbar,
        Money
    }
})
export default class GuaranteeFormSearchProductByOrder extends Vue {
    orderCode = '';
    customerCode = '';
    productCode = '';
    fromDate = '';
    toDate = '';
    isShow = false;
    configDateTimePicker = {
        enableTime: false,
        dateFormat: 'd-m-Y'
    };
    products: ProductOrderSearchResult[] = [];
    selected = [];
    currentPage = 1;
    isLoading = true;
    totalPage = 0;
    pageSize = 10;
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };
    moneyOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };

    @Watch('isLoading')
    onLoading(value: boolean) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#orderProductSearchButton',
                scale: 0.45
            })
        } else {
            this.$vs.loading.close('#orderProductSearchButton > .con-vs-loading')
        }
    }

    @Watch('currentPage')
    onPageChange() {
        this.search();
    }

    show() {
        this.isShow = true;
        this.search();
    }

    selectProduct(product: ProductOrderSearchResult) {
        console.log(product);
    }

    search() {
        this.isLoading = true;
        new GuaranteeService()
            .searchOrderProduct(this.orderCode, this.customerCode, this.productCode, this.fromDate, this.toDate, this.currentPage)
            .then((result: SearchResult<ProductOrderSearchResult>) => {
                this.isLoading = false;
                this.products = result.items.map((product: ProductOrderSearchResult) => {
                    product.totalAmount = product.price * product.quantity;
                    return product;
                });
                this.totalPage = Math.ceil(result.totalRows / this.pageSize)
            }).catch(() => this.isLoading = false);
    }

    accept() {
        this.$emit('accepted', cloneDeep(this.selected));
        this.selected = [];
        this.isShow = false;
    }

    close() {

    }
}
