export class ActionButton {
    type: string;
    color: string;
    icon: string;
    text: string;
    classes: string;
    tooltip?: string;
    tooltipPosition?: string;
    callback?: Function;

    constructor(type?: string, color?: string, icon?: string, text?: string, classes?: string, callback?: Function, tooltip?: string, tooltipPosition?: string) {
        this.type = type ? type : '';
        this.color = color ? color : 'primary';
        this.icon = icon ? icon : '';
        this.text = text ? text : '';
        this.classes = classes ? classes : '';
        this.callback = callback;
        this.tooltip = tooltip;
        this.tooltipPosition = tooltipPosition;
    }
}
