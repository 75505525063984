



















import { Component, Vue } from "vue-property-decorator";
import { ActionButton } from "@/views/order/order-edit/cell-renderer/ActionButton/ActionButton";

@Component
export default class ActionButtonCellRenderer extends Vue {
    params: any = null;
    actionButtons = [];

    created() {
        this.actionButtons = this.params.actionButtons;
    }

    onClick(actionButton: ActionButton, index: number) {
        if (typeof (actionButton.callback) === 'function') {
            actionButton.callback(this.params.data);
        }
    }
}
