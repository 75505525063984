import {GuaranteeDetailStatus, GuaranteeSourceType, GuaranteeStatus} from '@/views/guarantee/models/GuaranteeStatus';
import { v4 as uuidv4 } from 'uuid';

export class GuaranteeDetail {
    id: string;
    guaranteeId: string;
    concurrencyStamp?: string;
    code: string;
    productId: string;
    productCode: string;
    productName: string;
    productImage: string;
    orderCode: string;
    referenceCode: string;
    receiveStatus: string;
    status: GuaranteeStatus;
    deliveryDate: string | null;
    responsibleId: string;
    responsibleFullName: string;
    note: string;
    quantity: number;
    totalQuantity: number;
    price: number;
    providerId: string | null;
    providerName: string | null;
    unitId: string;
    unitName: string;
    sourceType: GuaranteeSourceType;

    constructor() {
        this.id = uuidv4();
        this.guaranteeId = '';
        this.code = ''
        this.productId = '';
        this.productCode = '';
        this.productName = '';
        this.productImage = '';
        this.orderCode = '';
        this.referenceCode = '';
        this.receiveStatus = '';
        this.status = GuaranteeStatus.New;
        this.deliveryDate = null;
        this.responsibleId = '';
        this.responsibleFullName = '';
        this.note = '';
        this.quantity = 1;
        this.totalQuantity = 1;
        this.price = 0;
        this.providerId = '';
        this.providerName = '';
        this.sourceType = GuaranteeSourceType.Product;
    }

    get statusText() {
        return this.status == GuaranteeStatus.New ? 'Mới'
            : this.status == GuaranteeStatus.Completed ? 'Đã trả hàng'
                : '';
    }
}
