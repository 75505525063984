import { GuaranteeDetail } from "@/views/guarantee/models/GuaranteeDetail";
import { FileResult } from "@/components/upload-file/FileResult";

export class Guarantee {
    id: string;
    code: string;
    referenceCode: string;
    name: string;
    customerId: string;
    customerName: string;
    customerPhoneNumber: string;
    totalAmount: number;
    totalItem: number;
    responsibleId: string;
    responsibleFullName: string;
    status: number;
    note: string;
    creatorId: string;
    creatorFullName: string;
    concurrencyStamp: string;
    details: GuaranteeDetail[];
    attachments: FileResult[];

    constructor() {
        this.id = '';
        this.code = '';
        this.name = '';
        this.referenceCode = '';
        this.customerId = '';
        this.customerName = '';
        this.customerPhoneNumber = '';
        this.totalAmount = 0;
        this.totalItem = 0;
        this.responsibleId = '';
        this.responsibleFullName = '';
        this.status = 0;
        this.note = '';
        this.creatorId = '';
        this.creatorFullName = '';
        this.concurrencyStamp = '';
        this.details = [];
        this.attachments = [];
    }
}
