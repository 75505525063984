
























































































import { Component, Emit, Ref, Vue, Watch } from "vue-property-decorator";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import i18n from "@/i18n";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import TreeSelect from "@/components/TreeSelect.vue";
import vSelect from "vue-select";
import ProvinceDistrictSelect from "@/components/ProvinceDistrictSelect.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CategorySelect from "@/components/category/CategorySelect.vue";
import { GuaranteeDetail } from "@/views/guarantee/models/GuaranteeDetail";
import CustomerSuggestion from "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue";
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";
import { Money } from "v-money";
import cloneDeep from 'lodash/cloneDeep';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

extend("required", {
    ...required,
    message: (_: string, values: any) => i18n.t("validations.required", [_])
} as any);
@Component({
    components: {
        Money,
        flatPickr,
        CustomerGroupTreeDropdown,
        UserSuggestion,
        TreeSelect,
        ValidationObserver,
        ValidationProvider,
        vSelect,
        ProvinceDistrictSelect,
        VuePerfectScrollbar,
        CategorySelect,
        CustomerSuggestion
    }
})
export default class GuaranteeAddProductForm extends Vue {
    @Ref('productName') productName: any;
    @Ref('quantity') quantity: any;
    guaranteeDetail: GuaranteeDetail = new GuaranteeDetail();
    isShow = false;
    isCreateAnother = false;
    configDateTimePicker = {
        enableTime: false,
        dateFormat: 'd-m-Y'
    };

    moneyOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };

    @Watch('isShow')
    onShown(value: boolean) {
        if (value) {
            this.focusName();
        }
    }

    @Emit('productAdded')
    onProductAdded(guaranteeDetail: GuaranteeDetail) {
        return guaranteeDetail;
    }

    onProviderSelected(data: SuggestionModel[]) {
        if (data && data.length > 0) {
            this.guaranteeDetail.providerId = data[0].id;
            this.guaranteeDetail.providerName = data[0].text;
        }
    }

    onProviderRemoved(data: any) {
        this.guaranteeDetail.providerId = null;
        this.guaranteeDetail.providerName = null;
    }

    show() {
        this.isShow = true;
    }

    save() {
        if (!this.guaranteeDetail.productName) {
            this.$vs.notify({
                title: 'Thông báo',
                text: 'Vui lòng nhập tên sản phẩm.',
                color: 'warning'
            });
            this.focusName();
            return;
        }

        if (!this.guaranteeDetail.quantity) {
            this.$vs.notify({
                title: 'Thông báo',
                text: 'Vui lòng nhập số lượng.',
                color: 'warning'
            });
            this.focusQuantity();
            return;
        }

        this.onProductAdded(cloneDeep(this.guaranteeDetail));
        this.resetModel();
        if (!this.isCreateAnother) {
            this.isShow = false;
        }
    }

    private resetModel() {
        this.guaranteeDetail = new GuaranteeDetail();
    }

    private focusName() {
        setTimeout(() => {
            this.productName.$el.querySelector('input').focus();
        }, 100);
    }

    private focusQuantity() {
        setTimeout(() => {
            this.quantity.$el.focus();
        }, 100);
    }
}
