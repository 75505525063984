export enum GuaranteeStatus
{
    /// <summary>
    /// Nháp.
    /// </summary>
    Draft,
    /// <summary>
    /// Mới.
    /// </summary>
    New,
    /// <summary>
    /// Hoàn thành.
    /// </summary>
    Completed
}

export enum GuaranteeDetailStatus
{
    New,
    Completed
}

/// <summary>
/// Nguồn của sản phẩm trong chi tiết phiếu bảo hành.
/// </summary>
export enum GuaranteeSourceType
{
    /// <summary>
    /// Từ sản phẩm.
    /// </summary>
    Product,
    /// <summary>
    /// Từ đơn hàng.
    /// </summary>
    Order,
    /// <summary>
    /// Người dùng tự nhập
    /// </summary>
    Custom
}
