












import { Component, Ref, Vue } from "vue-property-decorator";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";

@Component({
    components: {
        UserSuggestion
    }
})
export default class UserCellEditor extends Vue {

    @Ref('customerSuggestion') customerSuggestion!: any;
    params: any = null;
    value: any = null;
    customerType = null;

    buttonText = '';

    get selectedUser() {
        const data = this.params.data;
        const text = data[this.params.textField];
        console.log(text);
        return data ? [new SuggestionModel(this.value, text ? text : '', '', '', true, data)]
            : [];
    }

    created() {
        this.value = this.params.value;
        this.buttonText = this.params.buttonText;
        this.customerType = this.params.customerType;
    }

    mounted() {
        setTimeout(() => {
            this.customerSuggestion.$el.querySelector('input').focus()
        }, 100);
    }

    getValue(): any {
        return this.value;
    }

    onUserSelected(users: SuggestionModel[]) {
        if (!users || users.length == 0) {
            return;
        }
        const user = users[0];
        this.value = user.id;
        if (typeof (this.params.valueChanged) === "function") {
            this.params.valueChanged(this.params.data, user);
        }

    }

    onSelectedUserRemoved() {
        this.value = null;
        if (typeof (this.params.valueChanged) === "function") {
            this.params.valueChanged(this.params.data, null);
        }
    }
}
