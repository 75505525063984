










import { Component, Ref, Vue } from "vue-property-decorator";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

@Component({
    components: {
        flatPickr
    }
})
export default class DateTimeCellEditor extends Vue {
    @Ref('flatDatetimePickerComponent') flatDatetimePickerComponent: any;

    params: any = null;
    value: any = null;
    placeholder = '';
    configDateTimePicker = {
        enableTime: false,
        dateFormat: 'd-m-Y',
        allowInput: true
    };

    created() {
        this.value = this.params.value;
        this.placeholder = this.params.placeholder;

        setTimeout(() => {
            this.flatDatetimePickerComponent.$el.focus();
        }, 100);
    }

    getValue(): any {
        return this.value;
    }

    onChangeDate(date: any) {
        this.value = date;
    }
}
