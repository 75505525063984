














































































































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import CellRendererActions from "@/views/order/order-edit/cell-renderer/CellRendererActions.vue";
import CellRendererName from "@/views/order/order-edit/cell-renderer/CellRendererName.vue";
import QuantityCellRenderer from "@/views/order/order-edit/cell-renderer/QuantityCellRenderer.vue";
import CustomPinnedRowRenderer from "@/views/order/order-list/CustomPinnedRowRenderer.vue";
import {AgGridVue} from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import NumbericCellEditor from "@/views/order/order-edit/cell-editor/NumbericCellEditor.vue";
import GuaranteeAddProductForm from "@/views/guarantee/guarantee-form/GuaranteeAddProductForm.vue";
import {GuaranteeDetail} from "@/views/guarantee/models/GuaranteeDetail";
import CustomerSuggestion from "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue";
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";
import {Guarantee} from "@/views/guarantee/models/Guarantee";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import i18n from "@/i18n";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import {Getter} from "vuex-class";
import {Money} from "v-money";
import {GuaranteeSourceType} from "@/views/guarantee/models/GuaranteeStatus";
import sumBy from "lodash/sumBy";
import {ProductSearchResult} from "@/models/product/ProductSearchResult";
import DateTimeCellEditor from "@/views/order/order-edit/cell-editor/DateTimeCellEditor.vue";
import ActionButtonCellRenderer
    from "@/views/order/order-edit/cell-renderer/ActionButton/ActionButtonCellRenderer.vue";
import {ActionButton} from "@/views/order/order-edit/cell-renderer/ActionButton/ActionButton";
import {GuaranteeService} from "@/views/guarantee/GuaranteeService";
import {ActionResult} from "@/models/ActionResult";
import GuaranteeFormSearchProductByOrder
    from "@/views/guarantee/guarantee-form/GuaranteeFormSearchProductByOrder.vue";
import {ProductOrderSearchResult} from "@/views/guarantee/models/ProductOrderSearchResult";
import DropDownCellEditor from "@/views/order/order-edit/cell-editor/DropDownCellEditor.vue";
import {CellValueChangedEvent} from "ag-grid-community";
import moment from "moment";
import CustomerCellEditor from "@/views/order/order-edit/cell-editor/CustomerCellEditor.vue";
import UserCellEditor from "@/views/order/order-edit/cell-editor/UserCellEditor.vue";
import vSelect from "vue-select";
import GuaranteePrint from "@/views/guarantee/GuaranteePrint.vue";
import UploadList from "@/views/upload-file/UploadList.vue";
import {FileResult} from "@/components/upload-file/FileResult";
import cloneDeep from "lodash/cloneDeep";
import {Suggestion} from "@/models/Suggestion";
import _find from "lodash/find";
import _each from "lodash/each";
import CellSelectOptionEditor from '@/components/ag-grid/cell-select-option-editor/CellSelectOptionEditor.vue';

extend("required", {
    ...required,
    message: (_: string, values: any) => i18n.t("validations.required", [_]),
} as any);
@Component({
    components: {
        AgGridVue,
        GuaranteeAddProductForm,
        CustomerSuggestion,
        ValidationObserver,
        ValidationProvider,
        flatPickr,
        UserSuggestion,
        Money,
        GuaranteeFormSearchProductByOrder,
        GuaranteePrint,
        UploadList,
        "v-select": vSelect,
        ProductSearch: () => import(/* webpackChunkName: "ProductSearch" */ "@/views/order/order-edit/product/ProductSearch.vue"),
    },
})
export default class GuaranteeForm extends Vue {
    @Ref("guaranteeAddProductForm") guaranteeAddProductForm!: GuaranteeAddProductForm;
    @Ref("guaranteeFormSearchProductByOrder") guaranteeFormSearchProductByOrder!: GuaranteeFormSearchProductByOrder;
    @Getter("oidcUser") currentUser!: any;
    @Ref("guaranteePrint") guaranteePrint!: any;

    id: string;
    guarantee: Guarantee = new Guarantee();
    data: GuaranteeDetail[] = [];
    gridOptions: any = null;
    gridColumnApi: any = null;
    gridApi: any = null;
    pinnedTopRowData: any = null;
    isLoading = false;
    listStatus = [];
    completedStatus = 2;
    cellRenderComponents: any = {
        CellRendererActions,
        CellRendererName,
        QuantityCellRenderer,
        ActionButtonCellRenderer,
        DropDownCellEditor,
    };
    columnDefs: any = null;
    defaultColDef: any = {
        // sortable: true,
        editable: false,
        resizable: true,
    };
    frameworkComponents: any = {
        CustomPinnedRowRenderer,
        "cellSelectOptionEditor": CellSelectOptionEditor
    };
    configDateTimePicker = {
        enableTime: false,
        dateFormat: "d-m-Y",
    };
    deliveryDate = null;

    moneyOptions = {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
    };

    @Watch("deliveryDate")
    onDeliveryDateChange(value: string) {
        if (this.guarantee.status === this.completedStatus) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Phiếu bảo hành này đã hoàn thành. Bạn không thể thay đổi thời gian trả hàng dự kiến..",
                color: "warning",
            });
            return;
        }

        this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Xác nhận`,
            text: `Bạn có chắc chắn muốn áp dụng ngày dự kiến trả cho toàn bộ sản phẩm chưa giao?`,
            acceptText: "Đồng ý",
            cancelText: "Hủy bỏ",
            accept: () => {
                // Cập nhập.
                const deliveryDate = value ? moment(value, "DD-MM-YYYY").format() : null;
                if (this.guarantee.id) {
                    new GuaranteeService()
                        .updateGuaranteeDetailDeliveryDate(this.guarantee.id, deliveryDate)
                        .then((result: ActionResult) => {
                            if (result.code > 0) {
                                this.$vs.notify({
                                    title: "Thông báo",
                                    text: result.message,
                                    color: "success",
                                });

                                // Cập nhật lại ngày dự kiến trả hàng của sản phẩm.
                                this.data.forEach((guaranteeDetail: GuaranteeDetail) => {
                                    guaranteeDetail.deliveryDate = value;
                                });
                                this.gridApi.refreshCells();
                            } else {
                                this.$vs.notify({
                                    title: "Thông báo",
                                    text: result.message,
                                    color: "danger",
                                });
                            }
                        })
                        .catch((error: ActionResult) => {
                            this.$vs.notify({
                                title: "Thông báo",
                                text: error.message,
                                color: "danger",
                            });
                        });
                }
                // Thêm mới
                else {
                    this.data.forEach((guaranteeDetail: GuaranteeDetail) => {
                        guaranteeDetail.deliveryDate = value;
                    });
                }

            },
        })
    }

    @Watch("isLoading")
    onLoading(value: boolean) {
        if (value) {
            this.$vs.loading({
                type: "default",
            })
        } else {
            this.$vs.loading.close()
        }
    }

    get selectedResponsible() {
        return this.guarantee.responsibleId
            ? [new SuggestionModel(this.guarantee.responsibleId, this.guarantee.responsibleFullName)]
            : [new SuggestionModel(this.currentUser.userId, this.currentUser.fullName)];
    }

    get selectedCustomer() {
        return this.guarantee.customerId
            ? [new SuggestionModel(this.guarantee.customerId, this.guarantee.customerName)]
            : [];
    }

    get totalAmount() {
        return sumBy(this.data, (detail: GuaranteeDetail) => {
            const price = detail.price ? detail.price : 0;
            const quantity = detail.quantity ? detail.quantity : 0;
            return price * quantity;
        });
    }

    get statusText() {
        var status: any = this.listStatus.find((status: any) => {
            return status.value === this.guarantee.status;
        });
        return !status ? "" : status.name;
    }

    // get statusColor() {
    //     return this.guarantee.status === this.guaranteeStatus.New ? 'primary'
    //         : this.guarantee.status === this.guaranteeStatus.Draft ? 'dark'
    //             : this.guarantee.status === this.guaranteeStatus.Completed ? 'success'
    //                 : '';
    // }

    async onProductSelected(product: ProductSearchResult) {
        const guaranteeDetail = new GuaranteeDetail();
        guaranteeDetail.productId = product.id.toString();
        guaranteeDetail.productName = product.name;
        guaranteeDetail.productImage = product.image;
        guaranteeDetail.price = product.price;
        guaranteeDetail.unitId = product.unitId.toString();
        guaranteeDetail.unitName = product.unitName;
        guaranteeDetail.sourceType = GuaranteeSourceType.Product;
        if (this.guarantee.id) {
            this.addNewGuaranteeDetail([guaranteeDetail]);
        }
        this.data.push(guaranteeDetail);
    }

    get isShowFormButton() {
        return this.guarantee.status != this.completedStatus;
    }

    get isReadOnly() {
        return this.$route.name === "guaranteeDetail";
    }

    beforeMount() {
        this.gridOptions = {
            rowSelection: "multiple",
            suppressCellSelection: false,
            localeText: {
                noRowsToShow: "Không có dữ liệu",
            },
            getRowNodeId: function (data: GuaranteeDetail) {
                return data.id;
            },
        };

        setTimeout(() => {
            this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
        });

        const id = this.$route.params.id;
        const routeName = this.$router;
        if (id) {
            this.id = id;
            this.getDetail(id);
        }

        // get list status.
        new GuaranteeService()
            .getListStatus()
            .then((result: any) => {
                this.listStatus = result;

                _each(this.listStatus, (item: any) => {
                    item.id = item.value;
                })

                if (this.listStatus) {
                    this.renderDefaultColumn();
                }
            });
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;

        this.guarantee.creatorId = this.currentUser.userId;
        this.guarantee.creatorFullName = this.currentUser.fullName;
        this.guarantee.responsibleId = this.currentUser.userId;
        this.guarantee.responsibleFullName = this.currentUser.fullName;
    }

    onAttachmentUploaded(files: FileResult[]) {
        this.guarantee.attachments = [...this.guarantee.attachments, ...files.map((file: FileResult) => {
            file.createTime = moment(file.createTime).format("DD/MM/YYYY hh:mm:ss");
            return file;
        })];
    }

    onAttachmentDeleted(ids: string[]) {
        ids.forEach(id => {
            const index = this.guarantee.attachments.findIndex((item: FileResult) => {
                return item.id == id;
            });
            this.$delete(this.guarantee.attachments, index);
        })
    }

    onGridReady() {

    }

    onRowSelected() {

    }

    onCustomerSelected(data: SuggestionModel[]) {
        if (data && data.length > 0) {
            const customer = data[0];
            this.guarantee.customerId = customer.id;
            this.guarantee.customerName = customer.text;
            this.guarantee.customerPhoneNumber = customer.data.phoneNumber;
        } else {
            this.guarantee.customerId = "";
            this.guarantee.customerName = "";
            this.guarantee.customerPhoneNumber = "";
        }
    }

    onCustomerRemoved() {
        this.guarantee.customerId = "";
        this.guarantee.customerName = "";
        this.guarantee.customerPhoneNumber = "";
    }

    onResponsibleSelected(data: SuggestionModel[]) {
        console.log(data);
        if (data && data.length > 0) {
            const responsible = data[0];
            this.guarantee.responsibleId = responsible.id;
            this.guarantee.responsibleFullName = responsible.text;
        }
    }

    addProduct() {
        this.guaranteeAddProductForm.show();
    }

    onNewProductAdded(guaranteeDetail: GuaranteeDetail) {
        guaranteeDetail.sourceType = GuaranteeSourceType.Custom;
        if (this.guarantee.id) {
            guaranteeDetail.deliveryDate = guaranteeDetail.deliveryDate ? moment(guaranteeDetail.deliveryDate, "DD-MM-YYYY").format() : null;
            this.addNewGuaranteeDetail([guaranteeDetail]);
        }
        this.data.push(guaranteeDetail);
    }

    onSelectProductFromOrderAccepted(data: ProductOrderSearchResult[]) {
        const listProduct = data.filter((item: ProductOrderSearchResult) => {
            const index = this.data.findIndex((guaranteeDetail: GuaranteeDetail) => {
                return guaranteeDetail.orderCode === item.orderCode && guaranteeDetail.productId === item.productId;
            });
            return index === -1;
        })
        const guaranteeDetails = listProduct.map((item: ProductOrderSearchResult) => {
            const guaranteeDetail = new GuaranteeDetail();
            guaranteeDetail.orderCode = item.orderCode;
            guaranteeDetail.productId = item.productId.toString();
            guaranteeDetail.productName = item.productName;
            guaranteeDetail.productCode = item.productCode;
            guaranteeDetail.quantity = 1;
            guaranteeDetail.totalQuantity = item.quantity;
            guaranteeDetail.price = item.price;
            guaranteeDetail.unitId = item.unitId.toString();
            guaranteeDetail.unitName = item.unitName;
            guaranteeDetail.sourceType = GuaranteeSourceType.Order;
            return guaranteeDetail;
        });
        if (this.guarantee.id) {
            this.addNewGuaranteeDetail(guaranteeDetails)
        }
        this.data = [...this.data, ...guaranteeDetails];
    }

    cancel() {

    }

    showFindByOrder() {
        this.guaranteeFormSearchProductByOrder.show();
    }

    onCellValueChanged(event: CellValueChangedEvent) {
        const col = event.colDef.field;
        if (event.oldValue === event.value && col !== "status") {
            return;
        }

        let newValue = event.newValue;

        const data: GuaranteeDetail = event.data;
        if (col === "totalAmount") {
            return;
        }

        if (col === "status") {
            const statusInfo: any = this.listStatus.find((status: Suggestion) => {
                return status.name === newValue;
            });
            if (statusInfo) {
                data.status  = statusInfo.id;
            }
        }

        if (col === "price" || col === "quantity") {
            // Cập nhật tổng tiền.
            const totalAmount = data.price * data.quantity;
            if (this.gridApi) {
                const rowNode = this.gridApi.getRowNode(data.id);
                if (rowNode) {
                    rowNode.setDataValue("totalAmount", totalAmount);
                }
            }
        }

        this.updateDetail(data);
    }

    save() {
        if (!this.data || this.data.length === 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng chọn sản phẩm yêu cầu.",
                color: "warning",
            });
            return;
        }

        if (!this.guarantee.customerId) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng chọn khách hàng.",
                color: "warning",
            });
            return;
        }

        if (!this.guarantee.name) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng nhập tên yêu cầu.",
                color: "warning",
            });
            return;
        }

        this.guarantee.details = this.data.map((item: GuaranteeDetail) => {
            item.productId = item.productId ? item.productId.toString() : "";
            item.unitId = item.unitId ? item.unitId.toString() : "";
            item.quantity = item.quantity ? +item.quantity : 0;
            item.deliveryDate = item.deliveryDate ? moment(item.deliveryDate, "DD-MM-YYYY").format() : null;
            item.price = item.price ? +item.price : 0;
            return item;
        });
        this.isLoading = true;
        if (this.guarantee.id) {
            new GuaranteeService()
                .updateGuarantee(this.guarantee.id, cloneDeep(this.guarantee))
                .then((result: ActionResult<string>) => {
                    this.isLoading = false;
                    this.guarantee.concurrencyStamp = result.data as string;
                    this.$vs.notify({
                        title: "Thông báo",
                        text: result.message,
                        color: "success",
                    });
                })
                .catch((error: ActionResult) => {
                    this.isLoading = false;
                    this.$vs.notify({
                        title: "Thông báo",
                        text: error.message,
                        color: "danger",
                    });
                });
        } else {
            new GuaranteeService()
                .insertGuarantee(cloneDeep(this.guarantee))
                .then((result: ActionResult<Guarantee>) => {
                    this.isLoading = false;
                    if (result.code > 0) {
                        this.$vs.notify({
                            title: "Thông báo",
                            text: result.message,
                            color: "success",
                        });
                        this.$router.push({name: "guaranteeList"});
                    }
                })
                .catch((error: ActionResult) => {
                    this.isLoading = false;
                    this.$vs.notify({
                        title: "Thông báo",
                        text: error.message,
                        color: "danger",
                    });
                });
        }
    }

    print() {
        if (!this.id) {
            return;
        }
        this.guaranteePrint.print([this.guarantee.id]);
    }

    private renderDefaultColumn() {
        this.columnDefs = [
            {
                headerName: "",
                field: "actions",
                pinned: "left",
                width: 100,
                resizable: false,
                cellRendererFramework: ActionButtonCellRenderer,
                cellRendererParams: {
                    actionButtons: [
                        new ActionButton("flat", "danger", "delete", "", "", (data: GuaranteeDetail) => {
                            this.deleteGuaranteeDetail(data);
                        }),
                    ],
                },
            },
            {
                headerName: "",
                field: "selection",
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: "left",
                resizable: false,
                suppressSizeToFit: true,
            },
            {
                headerName: "Mã đơn hàng",
                field: "orderCode",
                filter: false,
                sortable: false,
                width: 100,
                pinned: "left",
                suppressSizeToFit: true,
                editable: false,
            },
            {
                headerName: "Mã SP",
                field: "productId",
                filter: false,
                sortable: false,
                width: 100,
                pinned: "left",
                suppressSizeToFit: true,
                editable: (params: any) => {
                    const data = params.data;
                    return ""
                },
                autoHeight: true,
            },
            {
                headerName: "Tên SP",
                field: "productName",
                filter: false,
                sortable: false,
                width: 200,
                pinned: "left",
                editable: (params: any) => {
                    const data = params.data;
                    // return data.sourceType === GuaranteeSourceType.Custom && data.status !== GuaranteeDetailStatus.Completed
                    //     && !this.isReadOnly;
                    return false;
                },
            },
            {
                headerName: "Đơn vị tính",
                field: "unitName",
                filter: false,
                sortable: false,
                width: 70,
                editable: (params: any) => {
                    const data = params.data;
                    // return data.sourceType === GuaranteeSourceType.Custom && data.status !== GuaranteeDetailStatus.Completed
                    //     && !this.isReadOnly;
                    return false;
                },
            },
            {
                headerName: "Số lượng",
                headerClass: "number-cell",
                field: "quantity",
                width: 100,
                cellClass: "text-right",
                cellEditorFramework: NumbericCellEditor,
                cellEditorParams: {
                    type: "number",
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
                valueFormatter: (params: any) => {
                    const data = params.data;
                    const quantity = this.number_format(data.quantity, 0, ",", ".");
                    const totalQuantity = this.number_format(data.totalQuantity, 0, ",", ".");
                    return data.sourceType === GuaranteeSourceType.Order
                        ? `${quantity}/${totalQuantity}`
                        : quantity;
                },
                valueSetter: (params: any) => {
                    const data = params.data;
                    if (data.sourceType === GuaranteeSourceType.Order) {
                        if (params.newValue > data.totalQuantity) {
                            this.$vs.notify({
                                title: "Thông báo",
                                text: "Số lượng sửa chữa không được phép lớn hơn số lượng có trong đơn hàng.",
                                color: "warning",
                            });
                            return false;
                        }
                    }
                    data.quantity = params.newValue;
                    return true;
                },
            },
            {
                headerName: "Phí sửa chữa",
                headerClass: "number-cell",
                field: "price",
                width: 150,
                cellClass: "text-right",
                cellEditorFramework: NumbericCellEditor,
                cellEditorParams: {
                    type: "number",
                },
                cellRendererFramework: QuantityCellRenderer,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
            },
            {
                headerName: "Thành tiền",
                field: "totalAmount",
                filter: false,
                width: 100,
                editable: false,
                headerClass: "number-cell",
                cellClass: "text-right",
                valueGetter: (params: any) => {
                    const data = params.data;
                    const totalPrice = data.quantity * data.price;
                    return this.number_format(totalPrice, 0, ",", ".");
                },
            },
            {
                headerName: "Tình trạng SP",
                field: "receiveStatus",
                filter: false,
                sortable: false,
                width: 200,
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
            },
            {
                headerName: "Trạng thái",
                field: "status",
                filter: false,
                resizable: true,
                width: 150,
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
                cellRenderer: (params: any) => {
                    const data = params.data;
                    if ((typeof data.status) === "string") {
                        return `<a class="con-edit-td cursor-pointer">${data.status}</a>`
                    } else {
                        const info = _find(this.listStatus, (item: any) => {
                            return item.id === data.status;
                        })
                        if (info) {
                            return `<a class="con-edit-td cursor-pointer">${info.name}</a>`
                        } else {
                            return "";
                        }
                    }
                },
                cellEditor: "cellSelectOptionEditor",
                cellEditorParams: {
                    source: this.listStatus.map((item: Suggestion) => {
                        return item.name;
                    }),
                },
            },
            {
                headerName: "Người phụ trách",
                field: "responsibleId",
                filter: false,
                // sortable: false,
                resizable: true,
                width: 150,
                cellClass: "suggestion-cell",
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
                cellEditorFramework: UserCellEditor,
                cellEditorParams: {
                    textField: "responsibleFullName",
                    valueChanged: (guaranteeDetail: GuaranteeDetail, user: SuggestionModel) => {
                        guaranteeDetail.responsibleFullName = user ? user.text : "";
                    },
                },
                valueFormatter: (params: any) => {
                    return params.data.responsibleFullName;
                },
            },
            {
                headerName: "Nhà cung cấp",
                field: "providerId",
                filter: false,
                sortable: false,
                width: 180,
                cellClass: "suggestion-cell",
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
                cellEditorFramework: CustomerCellEditor,
                cellEditorParams: {
                    customerType: 1,
                    textField: "providerName",
                    valueChanged: (guaranteeDetail: GuaranteeDetail, customer: SuggestionModel) => {
                        guaranteeDetail.providerName = customer ? customer.text : "";
                    },
                },
                valueFormatter: (params: any) => {
                    return params.data.providerName;
                },
            },
            {
                headerName: "Dự kiến trả",
                field: "deliveryDate",
                filter: false,
                // sortable: false,
                cellEditorFramework: DateTimeCellEditor,
                width: 200,
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
                valueFormatter: (params: any) => {
                    const value = params.value;
                    if (value) {
                        if (moment(value, "DD-MM-YYYY").isValid()) {
                            return value;
                        }
                        return moment(value).format("DD-MM-YYYY");
                    }
                    return "";
                },
            },
            {
                headerName: "Ghi chú",
                field: "note",
                filter: false,
                sortable: false,
                width: 200,
                editable: (params: any) => {
                    return !this.isReadOnly;
                },
            },
        ];
    }

    private saveGuaranteeDetail(data: GuaranteeDetail) {

    }

    private deleteGuaranteeDetail(guaranteeDetail: GuaranteeDetail) {
        this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Xác nhận`,
            text: `Bạn có chắc chắn muốn xóa sản phầm ${guaranteeDetail.productName} ra khỏi phiếu yêu cầu?`,
            accept: () => {
                const index = this.data.findIndex((item: GuaranteeDetail) => {
                    return item.id === guaranteeDetail.id;
                });
                // Cập nhập.
                if (this.guarantee.id) {
                    const guaranteeService = new GuaranteeService();
                    guaranteeService.deleteGuaranteeDetail(this.guarantee.id, guaranteeDetail.id)
                        .then((result: ActionResult) => {
                            if (result.code > 0) {
                                this.$delete(this.data, index);
                            } else {
                                this.$vs.notify({
                                    title: "Thông báo",
                                    text: result.message,
                                    color: "danger",
                                    iconPack: "feather",
                                    icon: "icon-info",
                                });
                            }
                        })
                        .catch((error: ActionResult) => {
                            this.$vs.notify({
                                title: "Thông báo",
                                text: error.message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-info",
                            });
                        });
                }
                // Thêm mới
                else {
                    this.$delete(this.data, index);
                }

            },
        })
    }

    private getDetail(id: string) {
        new GuaranteeService()
            .getGuaranteeDetail(id)
            .then((result: ActionResult<Guarantee>) => {
                const guarantee = result.data;
                if (guarantee) {
                    this.guarantee = guarantee;
                    this.guarantee.attachments = this.guarantee.attachments ? this.guarantee.attachments.map((attachment: FileResult) => {
                        attachment.createTime = moment(attachment.createTime).format("DD/MM/YYYY hh:mm:ss");
                        return attachment;
                    }) : [];
                    this.data = guarantee.details.map((guaranteeDetail: GuaranteeDetail) => {
                        guaranteeDetail.deliveryDate = guaranteeDetail.deliveryDate ? moment(guaranteeDetail.deliveryDate).format("DD-MM-YYYY")
                            : "";
                        return guaranteeDetail;
                    });
                }
            })
            .catch((error: ActionResult) => {
                if (error.code === 404) {
                    console.log("not found");
                }
            });
    }

    private number_format(number: number, decimals: number, dec_point: string, thousands_sep: string) {
        // http://kevin.vanzonneveld.net
        // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +     bugfix by: Michael White (http://getsprink.com)
        // +     bugfix by: Benjamin Lupton
        // +     bugfix by: Allan Jensen (http://www.winternet.no)
        // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
        // +     bugfix by: Howard Yeend
        // +    revised by: Luke Smith (http://lucassmith.name)
        // +     bugfix by: Diogo Resende
        // +     bugfix by: Rival
        // +      input by: Kheang Hok Chin (http://www.distantia.ca/)
        // +   improved by: davook
        // +   improved by: Brett Zamir (http://brett-zamir.me)
        // +      input by: Jay Klehr
        // +   improved by: Brett Zamir (http://brett-zamir.me)
        // +      input by: Amir Habibi (http://www.residence-mixte.com/)
        // +     bugfix by: Brett Zamir (http://brett-zamir.me)
        // +   improved by: Theriault
        // +   improved by: Drew Noakes
        // *     example 1: number_format(1234.56);
        // *     returns 1: '1,235'
        // *     example 2: number_format(1234.56, 2, ',', ' ');
        // *     returns 2: '1 234,56'
        // *     example 3: number_format(1234.5678, 2, '.', '');
        // *     returns 3: '1234.57'
        // *     example 4: number_format(67, 2, ',', '.');
        // *     returns 4: '67,00'
        // *     example 5: number_format(1000);
        // *     returns 5: '1,000'
        // *     example 6: number_format(67.311, 2);
        // *     returns 6: '67.31'
        // *     example 7: number_format(1000.55, 1);
        // *     returns 7: '1,000.6'
        // *     example 8: number_format(67000, 5, ',', '.');
        // *     returns 8: '67.000,00000'
        // *     example 9: number_format(0.9, 0);
        // *     returns 9: '1'
        // *    example 10: number_format('1.20', 2);
        // *    returns 10: '1.20'
        // *    example 11: number_format('1.20', 4);
        // *    returns 11: '1.2000'
        // *    example 12: number_format('1.2000', 3);
        // *    returns 12: '1.200'
        const n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === "undefined") ? "," : thousands_sep,
            dec = (typeof dec_point === "undefined") ? "." : dec_point,
            toFixedFix = function (n: any, prec: any) {
                // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                var k = Math.pow(10, prec);
                return Math.round(n * k) / k;
            },
            s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || "").length < prec) {
            s[1] = s[1] || "";
            s[1] += new Array(prec - s[1].length + 1).join("0");
        }
        return s.join(dec);
    }

    private updateDetail(guaranteeDetail: GuaranteeDetail) {
        if (!this.guarantee.id) {
            return;
        }

        guaranteeDetail.quantity = guaranteeDetail.quantity ? +guaranteeDetail.quantity : 1;
        guaranteeDetail.price = guaranteeDetail.price ? +guaranteeDetail.price : 1;
        guaranteeDetail.deliveryDate = guaranteeDetail.deliveryDate ? moment(guaranteeDetail.deliveryDate, "DD-MM-YYYY").format() : null;

        // Cập nhật
        new GuaranteeService()
            .updateGuaranteeDetail(this.guarantee.id, guaranteeDetail.id, guaranteeDetail)
            .then((result: ActionResult<string>) => {
                if (result.code > 0) {
                    // Cập nhật lại giá trị row.
                    const updatedGuaranteeDetail = this.data.find((item: GuaranteeDetail) => {
                        return item.id === guaranteeDetail.id;
                    });
                    if (updatedGuaranteeDetail) {
                        updatedGuaranteeDetail.concurrencyStamp = result.data;
                    }
                } else {
                    this.$vs.notify({
                        title: "Thông báo",
                        text: result.message,
                        color: "warning",
                    });
                }
            })
            .catch(result => {
                this.$vs.notify({
                    title: "Thông báo",
                    text: result.message,
                    color: "warning",
                });
            });
    }

    private addNewGuaranteeDetail(guaranteeDetails: GuaranteeDetail[]) {
        new GuaranteeService()
            .insertGuaranteeDetail(this.guarantee.id, guaranteeDetails)
            .then((result: ActionResult) => {

            })
            .catch((error: any) => {
                this.$vs.notify({
                    title: "Thông báo",
                    text: error.message,
                    color: "danger",
                });
            })
    }
}
